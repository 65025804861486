import React, {ComponentType, createContext, useReducer} from 'react';

import { Action, initialState, reducer } from '@epiApp/store/epiData/reducer';

export const epiDataCtx = createContext([])

export const EpiDataProvider: ComponentType = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
      <epiDataCtx.Provider value={[state, dispatch]}>
        {children}
      </epiDataCtx.Provider>
  )
}