import React, { ReactNode } from 'react'
import Layout from 'src/components/Layouts/Public';

type Props = {
    children?: ReactNode
}

export const FallbackView = ({children} : Props) => {
return(<Layout>{children}</Layout>)
}

export default FallbackView