import React from 'react'
import getComponentTypeForContent from '@epiApp/helpers/GetComponentTypeForContent'
import FallbackView from '@epiApp/views/FallbackView'
import PageTemplates from './PageTemplates'
import { Props } from './types'

const PageComponentSelector = (props: Props) => {
    const importView = (componentType: any) => PageTemplates[componentType]
    
    function getComponentTypeForPage(model: any) {
        if(model === undefined) return;
        return getComponentTypeForContent(model.content, PageTemplates);
    }

    const pageType = getComponentTypeForPage(props.model)
    const View = importView(pageType)

    const viewModel = {
        data: {pancms: { ...props.model }},
        pageContext: props.PageContext
    }

    return View ? <View key='EpiserverView' {...viewModel} /> : <FallbackView />
}
export default PageComponentSelector
