type EnvObject = {
    env: string,
    host: string,
    apiUrl: string
}

export class ApiPathProvider {
    ENV_URLS = [
        {
            'env' : 'test',
            'host': 'rp-pm-dev.local',
            'apiUrl': 'https://cms.rp-pm-dev.local/Api/GraphQL'
        },
        {
            'env' : 'integration',
            'host': 'content.rp-pm-dev.pantamera.nu',
            'apiUrl': 'https://content.rp-pm-dev.pantamera.nu/Api/GraphQL'
        },
        {
            'env' : 'preprod',
            'host': 'content.rp-pm-test.pantamera.nu',
            'apiUrl': 'https://content.rp-pm-test.pantamera.nu/Api/GraphQL'
        },
        {
            'env' : 'production',
            'host': 'content.pantamera.nu',
            'apiUrl': 'https://content.pantamera.nu/Api/GraphQL'
        }
    ]

    currentHref = window.location.href

    UrlExistsInArray(currentUrl: string) : EnvObject {
        return this.ENV_URLS.filter(x => currentUrl.includes(x.host))[0]
    }

    GetApiUrl() {
        const match = this.UrlExistsInArray(this.currentHref)
        return match ? match.apiUrl : ''
    }
}

export default new ApiPathProvider()