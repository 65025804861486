import React from 'react'
import ReactDOM from 'react-dom'
import App from '@epiApp/src/App'
import GothamBlack from 'src/fonts/Gotham/Gotham-Black.woff2'
import GothamBook from 'src/fonts/Gotham/Gotham-Book.woff2'
import GothamLight from 'src/fonts/Gotham/Gotham-Light.woff2'
import GothamLightItalic from 'src/fonts/Gotham/Gotham-LightItalic.woff2'
import GothamMedium from 'src/fonts/Gotham/Gotham-Medium.woff2'
import PantameraBlack from 'src/fonts/Gotham/Pantamera-Black.woff2'
import 'src/styles/themes/pantamera/critical.scss'
import 'src/fonts/PanIcons/fonts.scss'


// Gatsby's Link overrides:
// Gatsby defines a global called ___loader to prevent its method calls from creating console errors you override it here
global['___loader'] = {
    enqueue: () => {},
    hovering: () => {},
}

// Gatsby internal mocking to prevent unnecessary errors
global['__PATH_PREFIX__'] = ''

// This is to utilized to override the window.___navigate method Gatsby defines
// and uses to report what path a Link would be taking us to if it wasn't inside
// the cms
window['___navigate'] = () => {
    return false
}

const preloadFonts = () => {
    const fonts = [
        { family: 'Gotham', face: GothamBlack, options: { style: 'normal', weight: '900' } },
        { family: 'Gotham', face: GothamBook, options: { style: 'normal', weight: 'normal' } },
        { family: 'Gotham', face: GothamLight, options: { style: 'normal', weight: '300' } },
        { family: 'Gotham', face: GothamLightItalic, options: { style: 'italic', weight: '300' } },
        { family: 'Gotham', face: GothamMedium, options: { style: 'normal', weight: '500' } },
        { family: 'Pantamera', face: PantameraBlack, options: { style: 'normal', weight: '500' } },
    ]
    fonts.forEach(font => {
        if (window['FontFace']) {
            const ff = new FontFace(font.family, `url(${font.face})`, font.options)
            ff.load().then(() => {
                document['fonts'].add(ff)
            })
        } else {
            require('src/fonts/Gotham/fonts.scss')
            const FontFaceObserver = require('fontfaceobserver')
            const ffo = new FontFaceObserver(font.family, font.options)
            ffo.load()
        }
    })
}

preloadFonts()

document.addEventListener("DOMContentLoaded", function () {
    ReactDOM.render(
        <App />,
        document.getElementById("pan-ope-app")
    )
})