import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import EpiContextProvider from '@epiApp/context/EpiContext'
import EpiDataContextProvider from '@epiApp/context/EpiDataContext'
import DefaultView from '@epiApp/views/DefaultView/DefaultView'
import client  from '@epiApp/apollo/client'
import EpiLayout from '@epiApp/components/EpiserverLayout';

export const App = () => {
    return (
        <ApolloProvider client={client}>
                <EpiContextProvider>
                    <EpiDataContextProvider>
                        <EpiLayout>
                            <DefaultView />
                        </EpiLayout>
                    </EpiDataContextProvider>
                </EpiContextProvider>
        </ApolloProvider>
    )
}

export default App