import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import fragmentMatcher from './fragmentMatcher'
import ApiPathProvider from '@epiApp/providers/api-path-provider';

const apiPathProvider = ApiPathProvider

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: apiPathProvider.GetApiUrl(),
      credentials: 'same-origin'
    })
  ]),
  cache: new InMemoryCache({
      fragmentMatcher,
      //addTypename: false
  })
})

export default client
