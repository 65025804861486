
export default function GetComponentTypeForContent(content: any, components: object) {
    // Here we will try to find a component that matches the content type name.
    if(content && content.item) {
        const item = content.item
        const match = Object.keys(components).find((key) => {
            return key === item.__typename
        })
        if(match) return item.__typename

        return null;
    }
    return null;
}