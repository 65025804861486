import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

// Resolve the queries with Pantameras types
// We are passing empty instead of type every type in the schema
const fm = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
        __schema: {
          types: [],
        },
      },
});

export default fm;