import React, { useContext, useEffect, useState } from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { StartPageCmsQuery } from 'src/fragments/pages/templates/start-page'
import { StandardPageCmsQuery } from 'src/fragments/pages/templates/standard-page'
import { MainMenuPageCmsQuery } from 'src/fragments/pages/templates/main-menu-page'
import { MyPagesContainerCmsQuery } from 'src/fragments/pages/mina-sidor/my-pages-container'
import { MyPagesStartPageCmsQuery } from 'src/fragments/pages/mina-sidor/my-pages-start-page'
import { MyPagesPageCmsQuery } from 'src/fragments/pages/mina-sidor/my-pages-page'
import { NewsPageCmsQuery } from 'src/fragments/pages/mina-sidor/news-page'
import { NewsContainerCmsQuery } from 'src/fragments/pages/mina-sidor/news-container'
import { FaqListPageCmsQuery } from 'src/fragments/pages/templates/faq-list-page'
import { FaqItemPageCmsQuery } from 'src/fragments/pages/templates/faq-item-page'
import { AgreementPageCmsQuery } from 'src/fragments/pages/templates/agreement-page'
import PageComponentSelector from '@epiApp/components/PageComponentSelector'
import { epiDataCtx } from '@epiApp/context/EpiDataContext/EpiDataContext'
import { epiStateCtx } from '@epiApp/context/EpiContext/EpiContext'
import FallbackView from '@epiApp/views/FallbackView'
import { IEpiBeta } from './types';

declare global {
    interface Window {
        epi: IEpiBeta
    }
}

function getTemplateQuery(componentType: string): Function | null {
    switch(componentType) {
        case 'StartPage': {
            return StartPageCmsQuery
        }
        case 'StandardPage': {
            return StandardPageCmsQuery
        }
        case 'MainMenuPage': {
            return MainMenuPageCmsQuery
        }
        case 'MyPagesContainer': {
            return MyPagesContainerCmsQuery
        }
        case 'MyPagesStartPage': {
            return MyPagesStartPageCmsQuery
        }
        case 'MyPagesPage': {
            return MyPagesPageCmsQuery
        }
        case 'NewsPage': {
            return NewsPageCmsQuery
        }
        case 'NewsContainer': {
            return NewsContainerCmsQuery
        }
        case 'FaqListPage': {
            return FaqListPageCmsQuery
        }
        case 'FaqItemPage': {
            return FaqItemPageCmsQuery
        }
        case 'AgreementPage': {
            return AgreementPageCmsQuery
        }
    }

    return null
}

export const DefaultView = () => {
    const [state, dispatch] = useContext(epiDataCtx)
    const epiContext = useContext(epiStateCtx)

    const [pageContext, setPageContext] = useState({} as any)

    const pageTemplateQuery = (componentType: string) => {
        let pageTemplate = getTemplateQuery(componentType)

        // Return null if we don't find a matching query
        if(!pageTemplate) return null

        return pageTemplate(
            parseInt(document.documentElement.dataset.parentid),
            parseInt(document.documentElement.dataset.id),
            document.documentElement.dataset.draft,
            document.documentElement.lang
        )
    }

    const templateQueryString = pageTemplateQuery(document.documentElement.dataset.typename)

    // Return our fallback view if we can't query the page template.
    if(templateQueryString === null) {
      return (<FallbackView><h2>Missing template or query for this page type.</h2></FallbackView>)
    }

    // Query for the page
    const pageQuery = gql`${templateQueryString}`

    // Fetch data on load
    const page_q = useQuery(pageQuery)

    // Fetch new data on content saved
    const [loadPreview, { called, loading, data }] = useLazyQuery(pageQuery, {fetchPolicy: "cache-and-network"});

    function LoadPreview(contentLink: string) {
        if(contentLink) {
            loadPreview()
        }
    }

    function HandleEpiContext() {
        // The `beta/epiReady` event only has `isEditable`, but the epi object has both.
        const context = {
            inEditMode: window.epi.beta.inEditMode,
            isEditable: window.epi.beta.isEditable
        }

        // Make the context available to all react components.
        epiContext.updateEditMode(context);

        // If we're in an editable context we want to update the model on every change by the editor
        if (window.epi.beta.isEditable) {
            window.epi.subscribe('beta/contentSaved', (message: any) => {
                LoadPreview(message.contentLink)
            })
        }
    }

    function HandleLoadEvent() {
        // Expect `epi` to be there after the `load` event. If it's not then we're
        // not in any editing context.
        if (!window.epi) {
            return;
        }

        // Check for beta and that ready is an actual true value (not just truthy).
        if (window.epi.beta && window.epi.beta.ready === true) {
            // `beta/epiReady` already fired.
            HandleEpiContext();

        // The subscribe method won't be available in View mode.
        } else if (window.epi.subscribe) {
            // Subscribe if the `beta/epiReady` event hasn't happened yet.
            window.epi.subscribe('beta/epiReady', () => HandleEpiContext());
        }
    }

    useEffect(() => {
        if(!loading && data !== undefined) {
            dispatch({type: 'epiDataModel/UPDATE_MODEL_BY_CONTENT_LINK', payload: data || {}})
        }
    }, [called, loading, data])

    // When we have data for out page
    useEffect(() => {
        /* For debugging:
            console.log('DefaultView.useEffect()', page_q.data, page_q.loading, page_q.error);
        */

        if(state && !state.modelLoaded) {
            if(state.model.url !== window.location.pathname) {
                if(!page_q.error && !page_q.loading) {
                    dispatch({
                        type: 'epiDataModel/UPDATE_MODEL_BY_URL',
                        payload: page_q.data
                    })
                }
            }
        }
    }, [page_q.data, page_q.error, page_q.loading])

    // On data change in the megaMenu and footerData
    useEffect(() => {
        setPageContext({
            id: document.documentElement.dataset.id,
            lang: document.documentElement.lang,
            megaMenu: state.megaMenu,
            footerData: state.footerData
        })
    }, [state.megaMenu, state.footerData])

    // On page load
    useEffect(() => {
        HandleLoadEvent()
    }, [])

    return <PageComponentSelector PageContext={pageContext} model={state.model} />
}

export default DefaultView
