import startPage from 'src/pages/templates/start-page'
import standardPage from 'src/pages/templates/standard-page'
import mainMenuPage from 'src/pages/templates/main-menu-page'
import myPagesContainer from 'src/pages/mina-sidor/mypagescontainer'
import myPagesStartPage from 'src/pages/mina-sidor/dashboard'
import myPagesPage from 'src/pages/mina-sidor/mypagespage'
import newsPage from 'src/pages/mina-sidor/news'
import newsContainer from 'src/pages/mina-sidor/newscontainer'
import faqListPage from 'src/pages/templates/faq-list-page'
import faqItemPage from 'src/pages/templates/faq-item-page'
import agreementPage from 'src/pages/templates/agreement-page'

/**
 * Object with all registered page templates.
 */
export const PageTemplates = {
    StartPage: startPage,
    StandardPage: standardPage,
    MainMenuPage: mainMenuPage,
    MyPagesContainer: myPagesContainer,
    MyPagesStartPage: myPagesStartPage,
    MyPagesPage: myPagesPage,
    NewsPage: newsPage,
    NewsContainer: newsContainer,
    FaqListPage: faqListPage,
    FaqItemPage: faqItemPage,
    AgreementPage: agreementPage
}

export default PageTemplates
