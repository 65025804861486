export const initialState = {
    model: {},
    megaMenu: {},
    footerData: {},
    modelLoaded: false
}

export type State = typeof initialState;

export type Action =
  | { type: 'epiDataModel/UPDATE_MODEL_BY_URL', payload: object}
  | { type: 'epiDataModel/UPDATE_MODEL_BY_CONTENT_LINK',  payload: object}
  | { type: 'epiDataModel/UPDATE_MEGA_MENU',  payload: object}
  | { type: 'epiDataModel/UPDATE_FOOTER_DATA',  payload: object}


export const reducer = (state: State, action: Action) => {
    switch (action.type) {

        case 'epiDataModel/UPDATE_MODEL_BY_URL':
            let newStateByUrl = {
                ...state,
                modelLoaded: true,
                model: action.payload,
            }
            return newStateByUrl

        case 'epiDataModel/UPDATE_MODEL_BY_CONTENT_LINK':
            let newStateNuContentLink = {
                ...state,
                modelLoaded: true,
                model: action.payload             
            }

            return newStateNuContentLink
        
        case 'epiDataModel/UPDATE_MEGA_MENU':
            let newMegaMenu = {
                ...state,
                megaMenu: action.payload
            }

            return newMegaMenu

        case 'epiDataModel/UPDATE_FOOTER_DATA':
            let newFooterData = {
                ...state,
                footerData: action.payload
            }

            return newFooterData            

        default: return state;
    }
}