import React, { createContext, ComponentType, useState } from 'react';

export const epiStateCtx = createContext({} as any);

export const EpiProvider: ComponentType = ({ children }) => {
  const [epiState, setEpiState] = useState({
    inEditMode: false,
    isEditable: false,
  });

  const updateEditMode = (editContext: any) => {
    setEpiState({
      inEditMode: editContext.inEditMode,
      isEditable: editContext.isEditable 
    });
  };

  return (
      <epiStateCtx.Provider value={{
        inEditMode: epiState.inEditMode,
        isEditable: epiState.isEditable,
        updateEditMode: (editContext: any) => updateEditMode(editContext)
      }}>
        {children}
      </epiStateCtx.Provider>
  )
}