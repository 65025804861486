import React, { useEffect, useContext } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { menusQuery } from 'constants/Queries/EpiserverQueries'
import { epiDataCtx } from '@epiApp/context/EpiDataContext/EpiDataContext';
import { Props } from './types'
/*
* Component to provide menu/footer data to pageContext.
*/
export const EpiLayout = (props: Props) => {
  const [state, dispatch] = useContext(epiDataCtx)

  // Main query to get menu for top nav (mega menu) and footer
  const NavAndFooterQuery = gql`${menusQuery(document.documentElement.lang)}`
  const { data, loading, error } = useQuery(NavAndFooterQuery)

  // Map mega menu
  const megaMenu = (megaMenu: any) => {
    const megaMenuitems = megaMenu.content.item.children.items
    const result: any[] = []
    if (megaMenuitems && megaMenuitems.length) {
        megaMenuitems.forEach((item: any) => {
            if (item && item.name) {
                const megaMenuTeaserContent = item.megaMenuTeaser
                    ? item.megaMenuTeaser.content
                    : {}
                const megaMenuTeaserLink = item.megaMenuTeaser
                    ? megaMenuTeaserContent.link
                        ? megaMenuTeaserContent.link.content
                        : {}
                    : {}
                let megaMenuTeaserObject = {}

                if (item.megaMenuTeaser) {
                    megaMenuTeaserObject = {
                        type: megaMenuTeaserContent.type,
                        heading: megaMenuTeaserContent.heading,
                        text: megaMenuTeaserContent.teaserText,
                        image: megaMenuTeaserContent.image,
                        imageAlignment: megaMenuTeaserContent.imageAlignment,
                        backgroundImage: megaMenuTeaserContent.backgroundImage,
                        colorTheme: megaMenuTeaserContent.colorTheme,
                        link: {
                            url: megaMenuTeaserLink.path,
                        },
                    }
                }
                if (item.visibleInMenu) {
                    const childItems: any = {
                        items: [],
                    }

                    megaMenuitems.forEach((childItem: any) => {
                        if (childItem.visibleInMenu) {
                            let shortCut = {}

                            if (
                                childItem.shortCut &&
                                childItem.shortCut.visibleInMenu
                            ) {
                                shortCut = {
                                    id: childItem.shortCut.id,
                                    name: childItem.shortCut.name,
                                    path: childItem.shortCut.path,
                                    menuIcon: childItem.shortCut.menuIcon,
                                    menuTagLine: childItem.shortCut.menuTagLine,
                                }
                            }

                            childItems.items.push({
                                id: childItem.id,
                                name: childItem.name,
                                path: childItem.path,
                                menuIcon: childItem.menuIcon,
                                menuTagLine: childItem.menuTagLine,
                                children: childItem.children,
                                shortCut: shortCut,
                            })
                        }
                    })

                    result.push({
                        id: item.id,
                        name: item.name,
                        menuIcon: item.menuIcon,
                        path: item.path,
                        megaMenuTeaser: megaMenuTeaserObject,
                        children: item.children,
                    })
                }
            }
        })
    }
    return result
  }

  // Map footer menu and social links
  const footerData = (footerMenu: any) => {
    const footerRoot = footerMenu.content.item
    const footerMenuItems = footerRoot.children.items
    const socialMediaLinks =
        footerRoot.socialMediaLinks && footerRoot.socialMediaLinks.filteredItems
    const lowerFooterLinks =
        footerRoot.lowerFooterLinks && footerRoot.lowerFooterLinks.filteredItems
    const resultItems: any[] = []
    // Check if visible in menu
    if (footerMenuItems && footerMenuItems.length) {
        footerMenuItems.forEach((section: any) => {
            const children: any[] = []
            if (section.children && section.children.items.length) {
                section.children.items.map((childItem: any) => {
                    if (childItem.visibleInFooter) {
                        children.push({
                            id: childItem.id,
                            text: childItem.name,
                            url: childItem.path,
                        })
                    }
                })
            }
            if (section.visibleInFooter) {
                resultItems.push({
                    id: section.id,
                    name: section.name,
                    menuIcon: section.menuIcon,
                    children: children,
                })
            }
        })
    }
    return {
        footerMenu: resultItems,
        footerLowerSocial: socialMediaLinks,
        footerLowerLinks: lowerFooterLinks,
    }
  }

  useEffect(() => {
    /* For debugging:
        console.log('EpiserverLayout.useEffect()', data, loading, error);
    */

    if(!loading && !error && data) {
      // Top navigation
      const megaMenuData = megaMenu(data)
      if (megaMenuData && megaMenuData.length > 0) {
        dispatch({type: 'epiDataModel/UPDATE_MEGA_MENU', payload: megaMenuData})
      }
      // Footer navigation
      const footerMenuData = footerData(data)
      if (footerMenuData && footerMenuData.footerMenu && footerMenuData.footerMenu.length > 0) {
        dispatch({type: 'epiDataModel/UPDATE_FOOTER_DATA', payload: footerMenuData})
      }
    }
  }, [data, loading, error])

  // gatsby container for styles, nothing else
  return (<div id="___gatsby" className="___gatsby--epi">{props.children}</div>)
}

export default EpiLayout
